.mobile {
  display: none;
}
@media print {
  .mobile {
    display: block;
    text-align: left;
  }

  .title {
    margin: 0;
  }

  .add {
    display: none !important;
  }
}

.services {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

@media (max-width: 850px) {
  .services {
    grid-template-columns: 1fr;
  }
}
