
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

@media (max-width: 1024px) {
  .container {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }
}

.title {
  font-weight: bold;
}

.content {
  margin: 1rem 0;
}
