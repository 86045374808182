.card {
  min-width: 275px;
  margin: 1em;
}

.cardContent {
  text-align: left;
}

.bullet {
  display: inline-block;
  margin: 0 2px;
  transform: scale(0.8);
}

.title {
  font-size: 14px;
}

.pos {
  margin-bottom: 12px;
}

.actions {
  display: flex;
}

.delete {
  margin-left: auto !important;
}

.chip {
  margin: 2px;
}

@media print {
  .cardContent,
  .card {
    display: none !important;
  }
}
