.container {
  text-align: left;
  background-color: hotpink;
  margin: 3rem;
  padding: 3rem;
}
@media (max-width: 1024px) {
  .container {
    margin: 1rem;
    padding: 1rem;
  }
}
