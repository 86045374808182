.form {
  margin: 1rem;
  position: relative;
}

.textField {
  font-size: 50px;
  margin: 2rem 0;
}

.save {
  display: grid;
  grid-gap: 1rem;
  margin-top: 1rem;
}

.loading {
  position: absolute;
  top: 3rem;
  left: 50%;
}
