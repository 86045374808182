.paper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    flex-direction: column;
    padding: 4rem;
    text-align: left;;
}
.label {
    text-align: left;
}
.form {
    
}

.control.control {
    margin: 1rem;
}

.result {
    border: 2px double black;
    margin: 1rem;
    padding: 1rem;
    background-color: lightgray;
    text-align: left;
}