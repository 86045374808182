
.container {
  --grid-cols: 10% 10% 30% 30% 20%;
}

.head,
.body {
  display: grid;
  grid-template-columns: var(--grid-cols);
  grid-gap: 0.5rem;
  margin: 0.3rem 0;
  font-size: 0.5rem;
}

.head {
  font-weight: bold;
}
