.container {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 2rem;
}

.label {
  font-size: 2rem;
}

.input {
  width: 2rem;
  height: 2rem;
}
