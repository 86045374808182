.form {
    margin: 1.5rem 1.5rem 0 1.5rem;
}

.times {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}

